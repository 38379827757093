import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as serviceWorker from './serviceWorker'

function importBuildTarget() { 
    if (process.env.REACT_APP_BUILD_TARGET === "ifttt") { 
      return import("./IFTTT.js");
    } else { 
      return import("./App.js");
    } 
  } 

importBuildTarget().then(({ default: Environment }) => 
  ReactDOM.render( 
    <Environment />,
    document.getElementById("root") 
  )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
